import BaseTemplate from '../Base/BaseTemplate'
import Button from '@/components-v2/atoms/Button'
import Typography from '@/components-v2/atoms/Typography'
import { useState, useEffect, useRef } from 'react'
import useAuth from '@/hooks/useAuth'
import { useWeb3React } from '@web3-react/core'
import { useAppSelector, useAppDispatch } from '@/state'
import { useRouter } from 'next/router'
import { useOrganizationId } from '@/utils/getOrganizationId'
import { useGetUserAccountQuery, useGetUserOrgAccountQuery } from '@/api/account-api'
import {
  useGetAuthenticatedProfileQuery,
  useGetMembersQuery
} from '@/slice/organization-members/organization-members.api'
import { useGetChainsQuery } from '@/api/chain-api'
import { setShowBanner, showBannerSelector } from '@/slice/platform/platform-slice'
import { userOrganizationsSelector } from '@/slice/account/account-slice'
import { useGetFiatCurrenciesQuery, useGetOrgSettingsQuery } from '@/api/org-settings-api'

import { PromoBanner } from '@/components-v2/molecules/PromoBanner'
import { useSendAnalysisMutation } from '@/api/analysis-api'
import { useGetOrganizationTrialQuery } from '@/api/organization-subscription'
import { useModalHook } from '@/components-v2/molecules/Modals/BaseModal/state-ctx'
import PromptModal from '@/components-v2/molecules/PromptModal'
import PromoUpgradePlanModal from '@/components-v2/molecules/PromoUpgradePlanModal'
import { PlanName, SubscriptionStatus, useGetSubscriptionQuery } from '@/api/subscription-api'
import WelcomeFreeTrialModal from '@/components-v2/molecules/WelcomeModal'
import ExpiredModal from '@/components-v2/molecules/ExpiredModal'
import { isMonetisationEnabled } from '@/config/constants'
import { showUpgradeModalSelector, showAddOnModalSelector } from '@/slice/subscription/subscription-slice'
import BuyAddOnModal from '@/components-v2/molecules/BuyAddOnModal'
import { LoaderHQ } from '@/components-v2/LoaderHQ'
import useAutoConnectAccount from '@/hooks-v2/web3Hooks/useAutoConnectAccount'
import { useGetPendingTransactionsNewQuery } from '@/slice/pending-transactions/pending-transactions.api'
import { useGetAllOrganizationIntegrationsQuery } from '@/api/organization-integrations'
import { useOrganizationIntegrationsQuery } from '@/api/merge-rootfi-api'
import { accountingIntegrationSelector } from '@/slice/org-integration/org-integration-selector'
import { useGetChartOfAccountsQuery } from '@/api/chart-of-accounts'
import { useGetOrganisationCryptocurrenciesQuery, useGetVerifiedCryptoCurrenciesQuery } from '@/api/cryptocurrencies'
import { useGetWalletsQuery, useSyncPendingTransactionsMutation } from '@/slice/wallets/wallet-api'
import { api } from '@/api'
import { useGetContactProviderQuery, useGetContactsQuery, useLazyGetBanksQuery } from '@/slice/contacts/contacts-api'
import { setBankList, setBankLoading } from '@/slice/contacts/contacts-slice'
import OnboardingModal from '@/components-v2/organisms/OnboardingModal'
import useStorage from '@/hooks-v2/utility/useStorage'
import { resetTransferSlice } from '@/slice/transfer/transfer.slice'

interface ChildProps {
  children: React.ReactNode
  className?: string
}
interface MainViewWithChildren extends React.FC<ChildProps> {
  Header: HeaderWithChildren
  Content: any
  Footer: any
}

interface HeaderWithChildren extends React.FC<ChildProps> {
  Left: HeaderLeftProps
  Right: HeaderRightProps
}

interface HeaderRightProps extends React.FC<ChildProps> {
  PrimaryCTA: React.FC<{
    label: string
    onClick?: any
    disabled?: boolean
    loading?: boolean
    classNames?: string
    leadingIcon?: any
    locked?: boolean
  }>
  SecondaryCTA: React.FC<{ label: string; onClick?: any; leadingIcon?: any; disabled?: boolean }>
  children?: React.ReactNode
}

interface HeaderLeftProps extends React.FC<ChildProps> {
  Title?: React.FC<ChildProps>
  children?: React.ReactNode
  className?: string
}

const MainView: MainViewWithChildren = ({ children }) => {
  const { isInitated } = useAutoConnectAccount()
  const { logoutRedirect } = useAuth()

  const dispatch = useAppDispatch()

  const { removeItem } = useStorage('session')
  const [triggerSendAnalysis] = useSendAnalysisMutation()
  const organizationId = useOrganizationId()
  const [isPreloading, setIsPreloading] = useState(false)
  const promptModalProvider = useModalHook({ defaultState: { isOpen: false } })
  const upgradePlanModalProvider = useModalHook({ defaultState: { isOpen: false } })
  const expiredModalProvider = useModalHook({ defaultState: { isOpen: false } })
  const freeTrialModalProvider = useModalHook({ defaultState: { isOpen: false } })
  const addOnModalProvider = useModalHook({ defaultState: { isOpen: false } })
  const accountingIntegration = useAppSelector(accountingIntegrationSelector)
  const [syncPendingTrigger, syncPendingResult] = useSyncPendingTransactionsMutation()
  const { isLoading: isLoadingPendingTransaction, refetch: refetchPendingTransactions } =
    useGetPendingTransactionsNewQuery(
      {
        organizationId,
        params: {
          blockchainIds: [],
          walletIds: []
        }
      },
      { skip: !organizationId }
    )

  const [showOnboardingModal, setShowOnboardingModal] = useState(false)

  const router = useRouter()
  const { data: chainsData, isUninitialized: chainsUninitialized } = useGetChainsQuery({})

  const {
    data: walletData,
    refetch: refetchWallet,
    isUninitialized: walletsUninitialized
  } = useGetWalletsQuery({ orgId: organizationId, params: { size: 999 } }, { skip: !organizationId })

  useGetFiatCurrenciesQuery({})

  useGetOrganisationCryptocurrenciesQuery(
    {
      organisationId: organizationId,
      params: {
        blockchainIds: chainsData?.data?.map((chain) => chain.id),
        walletIds: walletData?.items?.map((wallet) => wallet.id)
      }
    },
    {
      skip: !organizationId || walletsUninitialized || chainsUninitialized,
      refetchOnMountOrArgChange: true
    }
  )

  useGetVerifiedCryptoCurrenciesQuery({})

  useGetChartOfAccountsQuery({ organizationId, params: { status: ['ACTIVE'] } }, { skip: !organizationId })
  const { isFetching: organizationIntegrationsLoading, refetch } = useGetAllOrganizationIntegrationsQuery(
    { organizationId },
    { skip: !organizationId, refetchOnMountOrArgChange: true }
  )
  const { isError: organizationIntegrationsError } = useOrganizationIntegrationsQuery(
    {
      organizationId,
      integration: accountingIntegration?.integrationName
    },
    { skip: !organizationId || !accountingIntegration?.integrationName, refetchOnMountOrArgChange: true }
  )
  const { refetch: orgAccountRefetch } = useGetUserOrgAccountQuery({})
  const {
    data: subscription,
    isFetching: isSubscriptionFetching,
    refetch: subscriptionRefetch
  } = useGetSubscriptionQuery({ organizationId }, { skip: !organizationId })
  const {
    isLoading: isOrgSettingLoading,
    refetch: orgSettingsRefetch,
    isUninitialized: isOrgSettingsUninitialized
  } = useGetOrgSettingsQuery({ orgId: organizationId }, { skip: !organizationId })
  const {
    data: organizationTrialData,
    isSuccess,
    isLoading: isOrganizationTrialLoading,
    isUninitialized: isOrganizationTrialUninitialized
  } = useGetOrganizationTrialQuery({ organizationId: String(organizationId) }, { skip: !organizationId })
  const { data: contactProviders, refetch: contactProvidersRefetch } = useGetContactProviderQuery(
    {
      orgId: organizationId
    },
    { skip: !organizationId }
  )
  const { refetch: contactsRefetch } = useGetContactsQuery(
    { orgId: organizationId, params: { size: 9999 } },
    { skip: !organizationId }
  )
  const { refetch: membersRefetch } = useGetMembersQuery(
    {
      orgId: organizationId,
      params: { state: 'active', size: 999 }
    },
    { skip: !organizationId }
  )
  const [triggerGetBank, getBankRes] = useLazyGetBanksQuery()
  const showBanner = useAppSelector(showBannerSelector)
  const listOrganization = useAppSelector(userOrganizationsSelector)
  const showUpgradeModal = useAppSelector(showUpgradeModalSelector)
  const showAddOnModal = useAppSelector(showAddOnModalSelector)
  const currentOrganization = listOrganization?.find((item) => item.id === organizationId)

  const { data: account } = useGetUserAccountQuery({})
  const { data: orgProfile, refetch: refetchUserProfile } = useGetAuthenticatedProfileQuery(
    { orgId: String(organizationId) },
    { skip: !organizationId }
  )

  useEffect(() => {
    if (
      organizationId &&
      listOrganization?.length > 0 &&
      listOrganization?.findIndex((_org) => _org.id === organizationId) < 0
    ) {
      logoutRedirect()
    }
  }, [organizationId, listOrganization])

  // TODO: Remove these legacy hooks when we have completely moved over to the new API
  useEffect(() => {
    if (organizationId) {
      contactsRefetch()
      contactProvidersRefetch()
      refetchWallet()
      refetchUserProfile()
      orgSettingsRefetch()
      subscriptionRefetch()
      orgAccountRefetch()
      membersRefetch()
      syncPendingTrigger({ organisationId: organizationId })
      refetchPendingTransactions()
    }
  }, [dispatch, organizationId])

  // old banner
  useEffect(() => {
    if (!isMonetisationEnabled) {
      if (organizationTrialData?.data?.length > 0) {
        if (organizationTrialData?.data?.[0]?.status === 'free_trial') {
          dispatch(setShowBanner(true))
        } else {
          dispatch(setShowBanner(false))
        }
      } else {
        dispatch(setShowBanner(true))
      }
    }
  }, [isSuccess, organizationTrialData])

  useEffect(() => {
    if (organizationIntegrationsError) {
      dispatch(api.util.invalidateTags(['organization-integrations-list']))
    }
  }, [organizationIntegrationsError])
  // new banner
  useEffect(() => {
    if (isMonetisationEnabled) {
      if (
        subscription &&
        subscription?.planName === PlanName.FREE_TRIAL &&
        (!window.sessionStorage.getItem('show_banner_monetisation') ||
          (window.sessionStorage.getItem('show_banner_monetisation') &&
            !JSON.parse(window.sessionStorage.getItem('show_banner_monetisation')).includes(organizationId)))
      ) {
        dispatch(setShowBanner(true))
      } else {
        dispatch(setShowBanner(false))
      }
    }
  }, [subscription, organizationId])

  useEffect(() => {
    if (account?.data?.agreementSignedAt === null) {
      promptModalProvider.methods.setIsOpen(true)
    }
  }, [account])

  useEffect(() => {
    const getBankList = async () => {
      let list = []
      let page = 1
      let check = true

      dispatch(setBankLoading(true))

      try {
        do {
          const res = await triggerGetBank({
            orgId: organizationId,
            params: {
              size: 500,
              page
            }
          }).unwrap()

          if (!res?.length) {
            check = false
          } else {
            list = [...list, ...res]
            page += 1
          }
        } while (check)

        dispatch(setBankList(list))
      } catch (error) {
        console.error('Error when getting banks')
      } finally {
        dispatch(setBankLoading(false))
      }
    }

    if (organizationId) {
      getBankList()
    }
  }, [organizationId])

  useEffect(() => {
    if (subscription?.status === SubscriptionStatus.EXPIRED && !isSubscriptionFetching) {
      if (
        (window.localStorage.getItem('do-not-show-expired-modals-for-orgs') &&
          !JSON.parse(window.localStorage.getItem('do-not-show-expired-modals-for-orgs')).includes(organizationId)) ||
        !window.localStorage.getItem('do-not-show-expired-modals-for-orgs')
      ) {
        expiredModalProvider.methods.setIsOpen(true)
      }
    }
  }, [subscription?.status, organizationId, isSubscriptionFetching])

  useEffect(() => {
    if (router.query.welcome && currentOrganization) {
      freeTrialModalProvider.methods.setIsOpen(true)
    }
  }, [router.query.welcome, organizationId, currentOrganization])

  useEffect(() => {
    if (router.pathname && !router.pathname.includes('transfer')) {
      removeItem('crypto-payments')
      removeItem('fiat-payments')
      removeItem('is-payment-reviewed')
      dispatch(resetTransferSlice())
    }
  }, [router.pathname])

  useEffect(() => {
    upgradePlanModalProvider.methods.setIsOpen(showUpgradeModal)
  }, [showUpgradeModal])

  useEffect(() => {
    addOnModalProvider.methods.setIsOpen(showAddOnModal)
  }, [showAddOnModal])

  const handleOnClickCloseBanner = () => {
    triggerSendAnalysis({
      eventType: 'CLICK',
      metadata: {
        action: 'tos_accepted'
      }
    })
    dispatch(setShowBanner(false))
  }
  const handleOnClickBanner = () => {
    triggerSendAnalysis({
      eventType: 'CLICK',
      metadata: {
        action: 'promo_banner_cta'
      }
    })
    if (isMonetisationEnabled) {
      router.push(`/${organizationId}/orgsettings?activeTab=pricingAndPlans`)
    } else {
      setShowOnboardingModal(true)
    }
  }

  const handleNavigateToProfile = () => {
    router.push(`/${organizationId}/account`)
  }

  if (
    !isInitated ||
    isPreloading ||
    isOrganizationTrialLoading ||
    isOrgSettingLoading ||
    isOrganizationTrialUninitialized ||
    isOrgSettingsUninitialized ||
    isLoadingPendingTransaction
  ) {
    return (
      <div className="h-screen flex justify-center">
        <LoaderHQ />
      </div>
    )
  }

  return (
    <BaseTemplate>
      {showBanner && (
        <PromoBanner
          onClose={handleOnClickCloseBanner}
          onClickCTA={handleOnClickBanner}
          expiryDate={organizationTrialData?.data?.[0]?.expiredAt}
          plan={subscription}
        />
      )}
      <BaseTemplate.Header
        user={{
          firstName: account?.data?.firstName ?? '-',
          lastName: account?.data?.lastName ?? '-',
          loginCredentials: account?.data?.name ?? '-',
          role: orgProfile?.data?.role ?? '-'
        }}
        onClickLogout={logoutRedirect}
        onClickNavigateToProfile={handleNavigateToProfile}
        isLoading={organizationIntegrationsLoading}
        refetch={refetch}
      />
      <BaseTemplate.Body bannerDisplayed={showBanner}>
        <BaseTemplate.Body.Sidebar
          bannerDisplayed={showBanner}
          orgList={listOrganization}
          currentOrg={currentOrganization}
          user={{
            role: orgProfile?.data?.role ?? '-',
            firstName: account?.data?.firstName ?? '-',
            lastName: account?.data?.lastName ?? '-',
            loginCredentials: account?.data?.name ?? '-'
          }}
          plan={subscription}
        />
        <BaseTemplate.Body.Content bannerDisplayed={showBanner}>{children}</BaseTemplate.Body.Content>
      </BaseTemplate.Body>
      <OnboardingModal
        expiryDate={organizationTrialData?.data?.[0]?.expiredAt}
        showModal={showOnboardingModal}
        setShowModal={setShowOnboardingModal}
      />
      <PromptModal provider={promptModalProvider} />
      {isMonetisationEnabled && (
        <WelcomeFreeTrialModal provider={freeTrialModalProvider} orgName={currentOrganization?.name} />
      )}
      {isMonetisationEnabled && !isSubscriptionFetching && (
        <ExpiredModal provider={expiredModalProvider} planName={subscription?.planName} />
      )}
      {isMonetisationEnabled && <PromoUpgradePlanModal provider={upgradePlanModalProvider} />}
      {isMonetisationEnabled && <BuyAddOnModal provider={addOnModalProvider} />}
    </BaseTemplate>
  )
}

export const Header: HeaderWithChildren = ({ children, className }) => (
  <div className={`p-[24px] flex w-100 flex-row items-center h-[88px] justify-between ${className}`}>{children}</div>
)

export const Content: any = ({ children, className }) => (
  <div
    className={`bg-white w-100 md:ml-0 ml-4 md:mr-0 mr-4 h-100 px-2 pb-[12px] flex-col h-[calc(100vh-245px)]  overflow-y-auto grow font-inter ${className}`}
  >
    {children}
  </div>
)

export const Footer = ({ children }) => <div className="p-[24px] border-t border-grey-200">{children}</div>

const ContentLeft: HeaderLeftProps = ({ children }) => (
  <div className="flex  h-[88px] flex-row items-center">{children}</div>
)
const ContentRight: HeaderRightProps = ({ children }) => (
  <div className="flex flex-row gap-3  h-[88px] items-center">{children}</div>
)

const Title: React.FC<ChildProps> = ({ children }) => (
  <Typography classNames="font-bold" variant="heading2" color="primary">
    {children}
  </Typography>
)

const PrimaryCTA: React.FC<{ label: string }> = ({ label, ...rest }) => (
  <Button loading={false} {...rest} label={label} variant="black" height={40} />
)
const SecondaryCTA: React.FC<{ label: string; leadingIcon?: any; disabled?: boolean }> = ({ label, ...rest }) => (
  <Button {...rest} loading={false} label={label} variant="ghost" height={40} />
)

const MainViewFooter = ({ children, extraClassName }) => (
  <div className={`bg-white pl-4 pt-4 ${extraClassName}`}>{children}</div>
)

MainView.Header = Header
MainView.Content = Content
MainView.Footer = MainViewFooter

Header.Left = ContentLeft
Header.Right = ContentRight

ContentLeft.Title = Title

ContentRight.PrimaryCTA = PrimaryCTA
ContentRight.SecondaryCTA = SecondaryCTA

export default MainView
